import { default as indexPSSq7moaCfMeta } from "/home/forge/vispetta.com/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_937zRMguUCxwMeta } from "/home/forge/vispetta.com/pages/[...slug].vue?macro=true";
import { default as _91branch_93XP49GpjCcaMeta } from "/home/forge/vispetta.com/pages/book/[branch].vue?macro=true";
import { default as cancelNeQxDItzLEMeta } from "/home/forge/vispetta.com/pages/checkout/cancel.vue?macro=true";
import { default as successxR8BpjnmuGMeta } from "/home/forge/vispetta.com/pages/checkout/success.vue?macro=true";
import { default as _91encryptedBooking_93nfVKRZjLbxMeta } from "/home/forge/vispetta.com/pages/contract/continue/[encryptedBooking].vue?macro=true";
import { default as fossaltahgkRMLrIA4Meta } from "/home/forge/vispetta.com/pages/fossalta.vue?macro=true";
import { default as dashboard45dhwhNzkhMeta } from "/home/forge/vispetta.com/pages/old/dashboard.vue?macro=true";
import { default as forgot_45passwordqYf580pm2RMeta } from "/home/forge/vispetta.com/pages/old/forgot-password.vue?macro=true";
import { default as login2lrLlyBCu1Meta } from "/home/forge/vispetta.com/pages/old/login.vue?macro=true";
import { default as _91token_93x0KCnNnQjuMeta } from "/home/forge/vispetta.com/pages/old/password-reset/[token].vue?macro=true";
import { default as registeromXa6KnGrJMeta } from "/home/forge/vispetta.com/pages/old/register.vue?macro=true";
import { default as verify_45email8XPuuhQlDIMeta } from "/home/forge/vispetta.com/pages/old/verify-email.vue?macro=true";
import { default as our_45scootersm2IoF4MZp5Meta } from "/home/forge/vispetta.com/pages/our-scooters.vue?macro=true";
import { default as _91encryptedCustomer_933SBgfZ1an9Meta } from "/home/forge/vispetta.com/pages/profile/edit/[encryptedCustomer].vue?macro=true";
import { default as terms_45and_45conditionsK25LizUNZmMeta } from "/home/forge/vispetta.com/pages/terms-and-conditions.vue?macro=true";
import { default as who_45we_45areJJIZiwenefMeta } from "/home/forge/vispetta.com/pages/who-we-are.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/vispetta.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "slug___it",
    path: "/it/:slug(.*)*",
    component: () => import("/home/forge/vispetta.com/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    component: () => import("/home/forge/vispetta.com/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___de",
    path: "/de/:slug(.*)*",
    component: () => import("/home/forge/vispetta.com/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___nl",
    path: "/nl/:slug(.*)*",
    component: () => import("/home/forge/vispetta.com/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "book-branch___it",
    path: "/it/prenota/:branch()",
    component: () => import("/home/forge/vispetta.com/pages/book/[branch].vue").then(m => m.default || m)
  },
  {
    name: "book-branch___en",
    path: "/en/book/:branch()",
    component: () => import("/home/forge/vispetta.com/pages/book/[branch].vue").then(m => m.default || m)
  },
  {
    name: "book-branch___de",
    path: "/de/buchen/:branch()",
    component: () => import("/home/forge/vispetta.com/pages/book/[branch].vue").then(m => m.default || m)
  },
  {
    name: "book-branch___nl",
    path: "/nl/boeken/:branch()",
    component: () => import("/home/forge/vispetta.com/pages/book/[branch].vue").then(m => m.default || m)
  },
  {
    name: "checkout-cancel___it",
    path: "/it/acquisto/fallimento",
    component: () => import("/home/forge/vispetta.com/pages/checkout/cancel.vue").then(m => m.default || m)
  },
  {
    name: "checkout-cancel___en",
    path: "/en/purchase/cancel",
    component: () => import("/home/forge/vispetta.com/pages/checkout/cancel.vue").then(m => m.default || m)
  },
  {
    name: "checkout-cancel___de",
    path: "/de/kauf/abbrechen",
    component: () => import("/home/forge/vispetta.com/pages/checkout/cancel.vue").then(m => m.default || m)
  },
  {
    name: "checkout-cancel___nl",
    path: "/nl/aankoop/annuleren",
    component: () => import("/home/forge/vispetta.com/pages/checkout/cancel.vue").then(m => m.default || m)
  },
  {
    name: "checkout-success___it",
    path: "/it/acquisto/successo",
    component: () => import("/home/forge/vispetta.com/pages/checkout/success.vue").then(m => m.default || m)
  },
  {
    name: "checkout-success___en",
    path: "/en/purchase/success",
    component: () => import("/home/forge/vispetta.com/pages/checkout/success.vue").then(m => m.default || m)
  },
  {
    name: "checkout-success___de",
    path: "/de/kauf/erfolg",
    component: () => import("/home/forge/vispetta.com/pages/checkout/success.vue").then(m => m.default || m)
  },
  {
    name: "checkout-success___nl",
    path: "/nl/aankoop/succes",
    component: () => import("/home/forge/vispetta.com/pages/checkout/success.vue").then(m => m.default || m)
  },
  {
    name: "contract-continue-encryptedBooking___it",
    path: "/it/contratto/continua/:encryptedBooking()",
    component: () => import("/home/forge/vispetta.com/pages/contract/continue/[encryptedBooking].vue").then(m => m.default || m)
  },
  {
    name: "contract-continue-encryptedBooking___en",
    path: "/en/contract/continue/:encryptedBooking()",
    component: () => import("/home/forge/vispetta.com/pages/contract/continue/[encryptedBooking].vue").then(m => m.default || m)
  },
  {
    name: "contract-continue-encryptedBooking___de",
    path: "/de/vertrag/fortfahren/:encryptedBooking()",
    component: () => import("/home/forge/vispetta.com/pages/contract/continue/[encryptedBooking].vue").then(m => m.default || m)
  },
  {
    name: "contract-continue-encryptedBooking___nl",
    path: "/nl/contract/doorgaan/:encryptedBooking()",
    component: () => import("/home/forge/vispetta.com/pages/contract/continue/[encryptedBooking].vue").then(m => m.default || m)
  },
  {
    name: "fossalta___it",
    path: "/it/fossalta",
    component: () => import("/home/forge/vispetta.com/pages/fossalta.vue").then(m => m.default || m)
  },
  {
    name: "fossalta___en",
    path: "/en/fossalta",
    component: () => import("/home/forge/vispetta.com/pages/fossalta.vue").then(m => m.default || m)
  },
  {
    name: "fossalta___de",
    path: "/de/fossalta",
    component: () => import("/home/forge/vispetta.com/pages/fossalta.vue").then(m => m.default || m)
  },
  {
    name: "fossalta___nl",
    path: "/nl/fossalta",
    component: () => import("/home/forge/vispetta.com/pages/fossalta.vue").then(m => m.default || m)
  },
  {
    name: "index___it",
    path: "/it",
    component: () => import("/home/forge/vispetta.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/home/forge/vispetta.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/home/forge/vispetta.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___nl",
    path: "/nl",
    component: () => import("/home/forge/vispetta.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "old-dashboard___it",
    path: "/it/old/dashboard",
    meta: dashboard45dhwhNzkhMeta || {},
    component: () => import("/home/forge/vispetta.com/pages/old/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "old-dashboard___en",
    path: "/en/old/dashboard",
    meta: dashboard45dhwhNzkhMeta || {},
    component: () => import("/home/forge/vispetta.com/pages/old/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "old-dashboard___de",
    path: "/de/old/dashboard",
    meta: dashboard45dhwhNzkhMeta || {},
    component: () => import("/home/forge/vispetta.com/pages/old/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "old-dashboard___nl",
    path: "/nl/old/dashboard",
    meta: dashboard45dhwhNzkhMeta || {},
    component: () => import("/home/forge/vispetta.com/pages/old/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "old-forgot-password___it",
    path: "/it/old/forgot-password",
    meta: forgot_45passwordqYf580pm2RMeta || {},
    component: () => import("/home/forge/vispetta.com/pages/old/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "old-forgot-password___en",
    path: "/en/old/forgot-password",
    meta: forgot_45passwordqYf580pm2RMeta || {},
    component: () => import("/home/forge/vispetta.com/pages/old/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "old-forgot-password___de",
    path: "/de/old/forgot-password",
    meta: forgot_45passwordqYf580pm2RMeta || {},
    component: () => import("/home/forge/vispetta.com/pages/old/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "old-forgot-password___nl",
    path: "/nl/old/forgot-password",
    meta: forgot_45passwordqYf580pm2RMeta || {},
    component: () => import("/home/forge/vispetta.com/pages/old/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "old-login___it",
    path: "/it/old/login",
    meta: login2lrLlyBCu1Meta || {},
    component: () => import("/home/forge/vispetta.com/pages/old/login.vue").then(m => m.default || m)
  },
  {
    name: "old-login___en",
    path: "/en/old/login",
    meta: login2lrLlyBCu1Meta || {},
    component: () => import("/home/forge/vispetta.com/pages/old/login.vue").then(m => m.default || m)
  },
  {
    name: "old-login___de",
    path: "/de/old/login",
    meta: login2lrLlyBCu1Meta || {},
    component: () => import("/home/forge/vispetta.com/pages/old/login.vue").then(m => m.default || m)
  },
  {
    name: "old-login___nl",
    path: "/nl/old/login",
    meta: login2lrLlyBCu1Meta || {},
    component: () => import("/home/forge/vispetta.com/pages/old/login.vue").then(m => m.default || m)
  },
  {
    name: "old-password-reset-token___it",
    path: "/it/old/password-reset/:token()",
    meta: _91token_93x0KCnNnQjuMeta || {},
    component: () => import("/home/forge/vispetta.com/pages/old/password-reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "old-password-reset-token___en",
    path: "/en/old/password-reset/:token()",
    meta: _91token_93x0KCnNnQjuMeta || {},
    component: () => import("/home/forge/vispetta.com/pages/old/password-reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "old-password-reset-token___de",
    path: "/de/old/password-reset/:token()",
    meta: _91token_93x0KCnNnQjuMeta || {},
    component: () => import("/home/forge/vispetta.com/pages/old/password-reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "old-password-reset-token___nl",
    path: "/nl/old/password-reset/:token()",
    meta: _91token_93x0KCnNnQjuMeta || {},
    component: () => import("/home/forge/vispetta.com/pages/old/password-reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "old-register___it",
    path: "/it/old/register",
    meta: registeromXa6KnGrJMeta || {},
    component: () => import("/home/forge/vispetta.com/pages/old/register.vue").then(m => m.default || m)
  },
  {
    name: "old-register___en",
    path: "/en/old/register",
    meta: registeromXa6KnGrJMeta || {},
    component: () => import("/home/forge/vispetta.com/pages/old/register.vue").then(m => m.default || m)
  },
  {
    name: "old-register___de",
    path: "/de/old/register",
    meta: registeromXa6KnGrJMeta || {},
    component: () => import("/home/forge/vispetta.com/pages/old/register.vue").then(m => m.default || m)
  },
  {
    name: "old-register___nl",
    path: "/nl/old/register",
    meta: registeromXa6KnGrJMeta || {},
    component: () => import("/home/forge/vispetta.com/pages/old/register.vue").then(m => m.default || m)
  },
  {
    name: "old-verify-email___it",
    path: "/it/old/verify-email",
    meta: verify_45email8XPuuhQlDIMeta || {},
    component: () => import("/home/forge/vispetta.com/pages/old/verify-email.vue").then(m => m.default || m)
  },
  {
    name: "old-verify-email___en",
    path: "/en/old/verify-email",
    meta: verify_45email8XPuuhQlDIMeta || {},
    component: () => import("/home/forge/vispetta.com/pages/old/verify-email.vue").then(m => m.default || m)
  },
  {
    name: "old-verify-email___de",
    path: "/de/old/verify-email",
    meta: verify_45email8XPuuhQlDIMeta || {},
    component: () => import("/home/forge/vispetta.com/pages/old/verify-email.vue").then(m => m.default || m)
  },
  {
    name: "old-verify-email___nl",
    path: "/nl/old/verify-email",
    meta: verify_45email8XPuuhQlDIMeta || {},
    component: () => import("/home/forge/vispetta.com/pages/old/verify-email.vue").then(m => m.default || m)
  },
  {
    name: "our-scooters___it",
    path: "/it/i-nostri-scooters",
    component: () => import("/home/forge/vispetta.com/pages/our-scooters.vue").then(m => m.default || m)
  },
  {
    name: "our-scooters___en",
    path: "/en/our-scooters",
    component: () => import("/home/forge/vispetta.com/pages/our-scooters.vue").then(m => m.default || m)
  },
  {
    name: "our-scooters___de",
    path: "/de/unsere-scooter",
    component: () => import("/home/forge/vispetta.com/pages/our-scooters.vue").then(m => m.default || m)
  },
  {
    name: "our-scooters___nl",
    path: "/nl/onze-scooters",
    component: () => import("/home/forge/vispetta.com/pages/our-scooters.vue").then(m => m.default || m)
  },
  {
    name: "profile-edit-encryptedCustomer___it",
    path: "/it/profilo/modifica/:encryptedCustomer()",
    component: () => import("/home/forge/vispetta.com/pages/profile/edit/[encryptedCustomer].vue").then(m => m.default || m)
  },
  {
    name: "profile-edit-encryptedCustomer___en",
    path: "/en/profile/edit/:encryptedCustomer()",
    component: () => import("/home/forge/vispetta.com/pages/profile/edit/[encryptedCustomer].vue").then(m => m.default || m)
  },
  {
    name: "profile-edit-encryptedCustomer___de",
    path: "/de/profil/bearbeiten/:encryptedCustomer()",
    component: () => import("/home/forge/vispetta.com/pages/profile/edit/[encryptedCustomer].vue").then(m => m.default || m)
  },
  {
    name: "profile-edit-encryptedCustomer___nl",
    path: "/nl/profiel/bewerken/:encryptedCustomer()",
    component: () => import("/home/forge/vispetta.com/pages/profile/edit/[encryptedCustomer].vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___it",
    path: "/it/termini-e-condizioni",
    component: () => import("/home/forge/vispetta.com/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___en",
    path: "/en/terms-and-conditions",
    component: () => import("/home/forge/vispetta.com/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___de",
    path: "/de/allgemeine-geschaftsbedingungen",
    component: () => import("/home/forge/vispetta.com/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___nl",
    path: "/nl/algemene-voorwaarden",
    component: () => import("/home/forge/vispetta.com/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "who-we-are___it",
    path: "/it/chi-siamo",
    component: () => import("/home/forge/vispetta.com/pages/who-we-are.vue").then(m => m.default || m)
  },
  {
    name: "who-we-are___en",
    path: "/en/who-we-are",
    component: () => import("/home/forge/vispetta.com/pages/who-we-are.vue").then(m => m.default || m)
  },
  {
    name: "who-we-are___de",
    path: "/de/wer-wir-sind",
    component: () => import("/home/forge/vispetta.com/pages/who-we-are.vue").then(m => m.default || m)
  },
  {
    name: "who-we-are___nl",
    path: "/nl/wie-we-zijn",
    component: () => import("/home/forge/vispetta.com/pages/who-we-are.vue").then(m => m.default || m)
  }
]